<template>
  <div class="">
    <div class="tab-list">
      <div :class="activenum==item.id?'activeclass':''" v-for="(item,index) in tablist" :key="index"
           @click="changetab(item.path)">
        <span>{{ item.name }}</span>
      </div>
    </div>
    <el-card class="box-card">
      <div class="buyprop">
        <el-button type="primary" @click="addposition()">新增职级</el-button>
        <el-select v-model="xctype" placeholder="切换薪资模式" style="width: 180px;" @change="typeChange">
          <el-option label="按小时计算" :value="1"></el-option>
          <el-option label="按天计算" :value="2"></el-option>
          <el-option label="按月计算" :value="3"></el-option>
        </el-select>
      </div>
      <div style="font-size: 10px; color: red; margin-left: 3px">
        {{ '括号内数值为：按单月21.75个工作日，每天8个工作小时计算所得薪资，仅供参考，事实请按实际核验为准！' }}
      </div>
      <div class="" style="min-height: 500px;">
        <el-table :data="tableData" v-loading="table_loading" :show-header="false"
                  element-table_loading-text="加载中" class="boderline">
          <el-table-column prop="rankName" min-width="15%"></el-table-column>
          <el-table-column min-width="35%">
            <template slot-scope="scope">
<!--              <div class="">{{ scope.row.money }}/{{ scope.row.type == 1 ? '小时' : scope.row.type == 2 ? '天' : '月' }}</div>-->
              <div class="" v-if="scope.row.type === 1">{{ scope.row.money }} / {{'小时'}} (
                {{ xctype === 2?(scope.row.money*8):xctype === 3?(scope.row.money*8*21.75):(scope.row.money) | numFilter}}
                / {{ xctype === 1 ? '小时' : xctype === 2 ? '天' : '月' }})
              </div>
              <div class="" v-if="scope.row.type === 2">{{ scope.row.money }} / {{'天'}} (
                {{ xctype === 3?(scope.row.money*21.75):xctype === 1?(scope.row.money/8):(scope.row.money) | numFilter}}
                / {{ xctype === 1 ? '小时' : xctype === 2 ? '天' : '月' }})
              </div>
              <div class="" v-if="scope.row.type === 3">{{ scope.row.money }} / {{'月'}} (
                {{ xctype === 1?(scope.row.money/174):xctype === 2?(scope.row.money/21.75):(scope.row.money) | numFilter}}
                / {{ xctype === 1 ? '小时' : xctype === 2 ? '天' : '月' }})
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="description" min-width="25%"></el-table-column>

          <el-table-column min-width="25%">
            <template slot-scope="scope">
              <div class="caozuo">
                <el-button type="text" @click="addposition(scope.row.id, scope.row.type)">编辑</el-button>
                <el-button type="text" @click="del(scope.row.id)">删除</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>
    <!-- 编辑新增弹窗 -->
    <addposition></addposition>

  </div>
</template>

<script>
import Bus from "../../common/bus.js"
import addposition from "/src/components/enterprise/addposition.vue";//搜素的简历详情
export default {
  components: {
    addposition
  },
  data() {
    return {
      tablist: [
        {name: '企业基本信息', id: 1, path: '/basicinfo'},
        {name: '公司相册', id: 2, path: '/photoalbum'},
        {name: '考勤设置', id: 3, path: '/worktime'},
        {name: '职位等级设置', id: 4, path: ''},

      ],
      activenum: 4,
      table_loading: false,
      tableData: [],
      xctype: '',

    };
  },
  created() {
    this.getList()
  },
  activated() {
    this.getList()
  },
  filters: {
    numFilter(value) {
      let transformVal = Number(value).toFixed(1)
      let realVal = transformVal.substring(0, transformVal.length - 1)

      return Number(realVal)
    }
  },
  methods: {
    getList() {
      this.$api.getJoblevel('get').then(res => {
        this.tableData = res.data
        if (res.data.length > 0) {
          this.xctype = res.data[0].type
        } else {
          // this.xctype=1
          if (!window.sessionStorage.getItem("xctype")) {
            this.xctype = 1
          }
        }
        window.sessionStorage.setItem("xctype", this.xctype);
      })
    },
    typeChange(val) {
      // console.log("typeChange ----- val:", val)
      this.xctype = val - 0;
      // this.tableData.map  (item => {
      //   item.type = this.xctype
      // })
      // window.sessionStorage.setItem("xctype", this.xctype);
      // this.$api.saveJoblevel('post', this.tableData).then(() => {
      //   this.getList()
      // })
    },
    changetab(path) {
      // console.log(path)
      this.$router.push(path);
    },
    addposition(id, type) {
      let config = {
        show: true,
        list: this.tableData,
        id: id,
        type: type?type:this.xctype,
        callback: () => {
          this.$api.getJoblevel('get').then(res => {
            this.tableData = res.data
          })
        }
      }
      Bus.$emit('positionstuts', config)
    },
    del(id) {
      let arr = []
      this.tableData.map(item => {
        if (item.id != id) {
          arr.push(item)
        }
      })
      this.$api.saveJoblevel('post', arr).then(() => {
        this.getList()
        this.$message({
          message: '操作成功',
          type: 'success'
        })
      })
    }

  }


};
</script>

<style lang="less" scoped>
.tab-list {
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  div {
    margin-right: 30px;
    color: #666666;
  }

  .activeclass {
    color: #111111;
    position: relative;
    font-weight: 600;
  }

  .activeclass :after {
    position: absolute;
    content: '';
    width: 30px;
    height: 2px;
    bottom: -6px;
    background: #00BCFF;
    left: 30%;


  }
}

/deep/ .el-input__inner {
  border-radius: 0px;
}

/deep/ .el-card {
  border-radius: 0px;
  border: none;
  box-shadow: 0px 4px 13px 0px rgb(0 0 0 / 3%) !important;
}

/deep/ .el-card__body {
  padding: 0 30px;
}

.buyprop {
  margin: 30px 0 15px 0;

  /deep/ .el-button {
    border-radius: 0px;
    width: 135px;
    background: #00bcff;
    border: none;
    height: 40px;
    margin-right: 30px;
  }

}

.boderline {
  width: 100%;
  border-right: 1px solid #F3F3F3;
  border-left: 1px solid #F3F3F3;
  border-top: 1px solid #F3F3F3;

}

/deep/ .el-table td, /deep/ .el-table th {
  text-align: center;
  color: #111111;
  font-size: 14px;
}

.caozuo {
  text-align: center;

  .el-button {
    color: #00BCFF;
    margin: 0 20px;
  }
}

.sort {
  img {
    width: 14px;
    height: 18px;
    margin: 0 20px;
  }
}

.closeicon {
  position: absolute;
  top: -125px;
  right: 0;
  font-size: 26px;
  color: #f3f3f3;
}

.delete_box {
  line-height: 4;
  padding: 30px 0;
  // position: relative;
  // /deep/.el-card__body{
  // 	padding: 50px 30px 30px 30px !important;
  // }
  .wb-tit {
    padding: 17px 0;
    text-align: center;
    background-color: #f3f3f3;
    color: #000000;
    font-weight: 600;
    line-height: 1;
  }

  .input-box {
    /deep/ .el-input__inner {
      border-radius: 0px;
    }
  }

  .db-ts {
    text-align: center;
    color: #666666;
    line-height: 1.5;
  }

  .dialog-footer {

    /deep/ .el-input__inner {
      border-radius: 0px;
    }

    /deep/ .el-button {
      margin-top: 40px;
      border-radius: 0px;
      background: #00bcff;
      border: none;
      width: 190px;
      height: 40px;
    }

    .qx {
      background-color: #fff;
      border: 1px solid #00bcff;
      color: #00bcff;
      margin-right: 10px;
    }
  }
}


</style>
