<template>
  <div class="">
    <el-dialog :visible.sync="dialogVisible" width="700px" :show-close="false" :modal-append-to-body="false"
               :lock-scroll="false">
      <div class="details">
        <span class="closeicon" @click="closebox()"><i class="el-icon-error"></i></span>
        <div class="wb-tit font18">{{ tittext }}</div>
        <div class="updatabox">
          <el-form :model="infoValidateForm" ref="infoValidateForm" label-width="130px" class="demo-ruleForm">
            <el-form-item label="职级名称：" prop="rankName" :rules="[{ required: true, message: '请输入职级名称'}]">
              <el-input v-model="infoValidateForm.rankName" placeholder="请输入职级名称"></el-input>
            </el-form-item>
            <el-form-item label="薪资：" prop="money" :rules="[{ required: true, message: '请设置薪资'}]">
              <div class="" style="display: flex;">
                <el-input placeholder="请设置薪资" v-model="infoValidateForm.money" style="width: 85%;"
                          maxlength="9" onkeyup="value=value.replace(/[^\d]/g,'')" v-on:input="numCheck">
                </el-input>
                <span style="margin-left: 5px"> 元 / </span>
                <span v-if="xctype===1" style="margin-left: 3px">小时</span>
                <span v-if="xctype===2" style="margin-left: 3px">天</span>
                <span v-if="xctype===3" style="margin-left: 3px">月</span>
              </div>
              <div v-if="warningTipFlag" style="font-size: 10px; color: #e9a43d; margin-left: 3px">
                {{ tips }}
              </div>
            </el-form-item>
            <el-form-item label="备注：" prop="description">
              <el-input
                  type="textarea"
                  :autosize="{ minRows: 8, maxRows: 10 }"
                  show-word-limit
                  placeholder="请输入描述"
                  v-model="infoValidateForm.description"
                  style=""
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" class="btn" @click="submitForm('infoValidateForm')">确定</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Bus from '../../common/bus.js';

export default {
  data() {
    return {
      dialogVisible: false,
      list: [],
      infoValidateForm: {
        id: '',
        rankName: '',
        money: '',
        description: '',
        type: 3,
        companyId: '',
      },
      xctype: 1,
      id: '',
      tittext: '',
      callback: null,
      warningTipFlag: false,
      tips: "",
    };
  },
  mounted() {
    Bus.$on('positionstuts', data => {
      // this.xctype = window.sessionStorage.getItem("xctype");
      this.dialogVisible = data.show;
      this.list = data.list
      this.id = data.id
      this.xctype = data.type;
      this.callback = data.callback
      if (data.id) {
        // debugger
        this.tittext = '编辑职级';
        this.list.map(item => {
          if (item.id == data.id) {
            for (let k in item) {
              if (k == 'type') {
                this.infoValidateForm[k] = this.xctype
              } else {
                if(k === 'money'){
                    let numMoney = item[k] - 0;
                    this.numCheck(numMoney)
                }
                this.infoValidateForm[k] = item[k]
              }
            }
          }
        })
      } else {
        this.tittext = '新增职级';
        for (let k in this.infoValidateForm) {
          if (k == 'type') {
            this.infoValidateForm[k] = this.xctype
          } else {
            this.infoValidateForm[k] = ''
          }
        }
      }
    });
  },
  methods: {
    closebox() {
      this.dialogVisible = false;
      // Bus.$emit('reportdstuts', false)
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          let arr = []
          if (this.infoValidateForm.id) {
            this.list.map(item => {
              if (this.infoValidateForm.id == item.id) {
                // console.log("submit form data = ", this.infoValidateForm);
                arr.push(this.infoValidateForm)
              } else {
                arr.push(item)
              }
            })
          } else {
            // console.log("submit with no ID list.data = ", this.list);
            this.list.map(item => {
              arr.push(item)
            })
            // console.log("submit form data with no ID = ", this.infoValidateForm);
            arr.push(this.infoValidateForm)
          }
          this.$api.saveJoblevel('post', arr).then(() => {
            this.callback()
            this.$message({
              message: '保存成功',
              type: 'success'
            })
            this.dialogVisible = false;
          })
        } else {
          return false;
        }
      });
    },
    handleCommand(value) {
      this.infoValidateForm.type = value
    },
    numCheck(value){
      let mNum = value - 0;
      // console.log("content check, ----- value = ", mNum);
      if(mNum === 0){
        this.warningTipFlag = false;
        return false;
      }
      this.tips = "请注意，已输入薪资数目未达到国家普遍最低工资标准！";
      if(this.xctype === 1){
        if(mNum*8*21.75 < 3000){
          this.warningTipFlag = true;
          return true;
        }
      }
      if(this.xctype === 2){
        if(mNum*21.75 < 3000){
          this.warningTipFlag = true;
          return true;
        }
      }
      if(this.xctype === 3){
        if(mNum < 3000){
          this.warningTipFlag = true;
          return true;
        }
      }
      this.warningTipFlag = false;
      return false;
    },
  }
};
</script>
<style type="text/css">
.el-dialog__header,
.el-dialog__body {
  padding: 0 !important;
}
</style>
<style lang="less" scoped>
.closeicon {
  position: absolute;
  top: -40px;
  right: 0;
  font-size: 26px;
  color: #f3f3f3;
}

.details {
  padding-bottom: 20px;

  .wb-tit {
    padding: 17px 0;
    text-align: center;
    background-color: #f3f3f3;
    color: #000000;
    font-weight: 600;
  }
}

.updatabox {
  .demo-ruleForm {
    padding: 50px 80px 0px 50px;

    /deep/ .el-input__inner,
    /deep/ .el-textarea__inner {
      border-radius: 0px;
    }

    /deep/ .el-button {
      border-radius: 0px;
      width: 100%;
      margin-top: 50px;
      background: #00bcff;
      border: none;
    }

    /deep/ .el-input-group__append,
    /deep/ .el-input-group__prepend {
      border-radius: 0 !important;
      background-color: #dddddd;
      color: #333333;
    }
  }
}

.danwei {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #dddddd;
  color: #333333;
  width: 60px;
  transform: translate(0px, 0.5px);
}
</style>
